<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getXML"
      :loading="loading">
      <v-icon left>mdi-file-xml</v-icon> Gerar XML (GLOSA)
    </v-btn>
  </v-container>
</template>

<script>
import api from "../../../http";
import moment from "moment";
import { create } from 'xmlbuilder2';
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import md5 from 'md5';

const { withCRUDUtils } = UtilsFunc

// funçõa para trocar acentos por item sem acentuação e remover caracter especial
function cleanText(input) {
  const accentsMap = {
    'á': 'a', 'Á': 'A', 'à': 'a', 'À': 'A', 'ã': 'a', 'Ã': 'A', 'â': 'a', 'Â': 'A',
    'é': 'e', 'É': 'E', 'è': 'e', 'È': 'E', 'ê': 'e', 'Ê': 'E',
    'í': 'i', 'Í': 'I', 'ì': 'i', 'Ì': 'I', 'î': 'i', 'Î': 'I',
    'ó': 'o', 'Ó': 'O', 'ò': 'o', 'Ò': 'O', 'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O',
    'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U', 'û': 'u', 'Û': 'U',
    'ç': 'c', 'Ç': 'C',
    'ü': 'u', 'Ü': 'U'
  };
  let output = input.split('').map(char => accentsMap[char] || char).join('');
  return output.replace(/[^a-zA-Z0-9\s]/g, ''); 
}


export default {
  name: "Produtividade",
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
  },
  data: () => withCRUDUtils({
    empresa: [],
    procedimentos: [],
    loading: false,
  }),
  methods: {
    async getXML() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimento(),
        ]);
        await this.generateXML()
        this.loading = false;
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data;
          const regex = /<pre class="exception_value">\(\d+,\s*&#x27;(.*?)&#x27;\)<\/pre>/;
          const match = errorMessage.match(regex);

          if (match && match[1]) {
            this.$toast.error(match[1]);
          } else {
            this.$toast.error("Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.");
          }
        } else if (error.request) {
          // A solicitação foi feita, mas não houve resposta do servidor
          console.error('No response from server:', error.request);
          this.$toast.error('Sem resposta do servidor. Verifique sua conexão com a internet.');
        } else {
          // Ocorreu um erro durante a configuração da solicitação
          console.error('Request error:', error.message);
          this.$toast.error(`Erro ao enviar a solicitação. Tente novamente mais tarde. ${error.message}`);
        }
      }
    },
    generateXML() {
      const empresa = this.empresa;
      const selectedGuides = this.selectedBudgets;
      const totalValuePerBudget = {};

      let procedimentos = [];

      for (const item of this.procedimentos.flat()) {
        if (!selectedGuides.includes(item.sessao.id)) {
          continue;
        }

        let calculoDesconto;
        let reducaoAcrescimo;

        if (item.desconto === null && item.acrescimo === null) {
          reducaoAcrescimo = '0';
        } else if (item.desconto !== null) {
          calculoDesconto = -(item.desconto);
          reducaoAcrescimo = calculoDesconto.toFixed(2);
        } else {
          reducaoAcrescimo = item.acrescimo.toFixed(2);
        }

        let valorCobranca = ((item.valor_cobranca * (reducaoAcrescimo / 100)) + item.valor_cobranca) * item.quantidade;

        if (!totalValuePerBudget[item.sessao.id]) {
          totalValuePerBudget[item.sessao.id] = [];
        }

        totalValuePerBudget[item.sessao.id].push(parseFloat(valorCobranca.toFixed(2)));
        
        procedimentos.push({
          'ans:sequencialItem': `00${(procedimentos.length + 1)}`,
          'ans:dataInicio': item.timestamp ? UtilsPDF.formatDateXML(item.timestamp) : item.data_especial,
          'ans:procRecurso': {
            'ans:codigoTabela': item.procedimento.tabela_tabela,
            'ans:codigoProcedimento': item.procedimento.codigo,
            'ans:descricaoProcedimento': item.procedimento.procedimento,
          },
          'ans:codGlosaItem': item.motivoglosa.codigo,
          'ans:valorRecursado': valorCobranca.toFixed(2),
          'ans:justificativaItem': cleanText(item.motivoglosa.nome),
        })
      }

      // Calcular a soma dos valores dos procedimentos
      const valorTotalGeral = procedimentos.reduce((acc, cur) => {
        return acc + parseFloat(cur['ans:valorRecursado']);
      }, 0);

      var obj = {
        'ans:mensagemTISS': {
          '@xmlns:ans': 'http://www.ans.gov.br/padroes/tiss/schemas',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          '@xsi:schemaLocation': 'http://www.ans.gov.br/padroes/tiss/schemas http://www.ans.gov.br/padroes/tiss/schemas/tissV4_01_00.xsd',
          'ans:cabecalho': {
            'ans:identificacaoTransacao': {
              'ans:tipoTransacao': 'RECURSO_GLOSA',
              'ans:sequencialTransacao': empresa.id,
              'ans:dataRegistroTransacao': UtilsPDF.formatDateXML(new Date()),
              'ans:horaRegistroTransacao': moment().format('HH:mm:ss')
            } //identificacaoTransacao
            , 'ans:origem': {
              'ans:identificacaoPrestador': {
                'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo
              } //identificacaoPrestador
            } //origem
            , 'ans:destino': {
              'ans:registroANS': empresa.convenio_ans
            } //destino
            , 'ans:Padrao': empresa.versao_xml
          }, //cabecalho
          'ans:prestadorParaOperadora': {
            'ans:recursoGlosa': {
              'ans:guiaRecursoGlosa': {
                'ans:registroANS': empresa.convenio_ans,
                'ans:numeroGuiaRecGlosaPrestador': empresa.nr_guia_prestador,
                'ans:nomeOperadora': empresa.convenio,
                'ans:objetoRecurso': '2',
                'ans:numeroGuiaRecGlosaOperadora': empresa.nr_guia_operador,
                'ans:dadosContratado': {
                  'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo
                }, //dadosContratado
                'ans:numeroLote': empresa.id,
                'ans:numeroProtocolo': empresa.nrprotocolorecursoglosa,
                'ans:opcaoRecurso': {
                  'ans:recursoGuia': {
                    'ans:numeroGuiaOrigem': '00000001',
                    'ans:opcaoRecursoGuia': {
                      'ans:itensGuia': procedimentos,
                    }, //opcaoRecursoGuia
                  }, //recursoGuia
                }, //opcaoRecurso
              'ans:valorTotalRecursado': valorTotalGeral.toFixed(2),
              'ans:dataRecurso': UtilsPDF.formatDateXML(new Date()),
              } // guiaRecursoGlosa
            } // recursoGlosa
          } //prestadorParaOperadora
        } //mensagemTISS
      }
      const root = create(obj)
      const xml = root.end({ prettyPrint: true, headless: true });

      const inicioCabecalho = xml.indexOf("<ans:cabecalho>");
      const fimCabecalho = xml.indexOf("</ans:cabecalho>");
      const cabecalho = xml.substring(inicioCabecalho, fimCabecalho + "</ans:cabecalho>".length);

      const inicioPrestador = xml.indexOf("<ans:prestadorParaOperadora>");
      const fimPrestador = xml.indexOf("</ans:prestadorParaOperadora>");
      const prestadorParaOperadora = xml.substring(inicioPrestador, fimPrestador + "</ans:prestadorParaOperadora>".length);

      const conteudoParaHash = cabecalho + prestadorParaOperadora;
      //  Retiramos as tags,  espaços, linhas em branco
      let conteudoTexto = conteudoParaHash.replace(/\n/g, '').replace(/>\s+</g, '').replace(/<[^>]+>/g, '').trim();
      // console.log(conteudoTexto)
      const hash = md5(conteudoTexto);
      var epilogo = { 'ans:epilogo': { 'ans:hash': hash } };
      obj['ans:mensagemTISS']['ans:epilogo'] = epilogo['ans:epilogo'];
      const rootWithEpilogue = create(obj)
      const xmlWithEpilogue = rootWithEpilogue.end({ prettyPrint: true, headless: true });
      const xmlWithEncoding = `<?xml version="1.0" encoding="iso-8859-1"?>\n${xmlWithEpilogue}`;
      const nomePaciente = empresa.paciente.replace(/\s/g, "_")
      const nomeConvenio = empresa.convenio.replace(/\s/g, "_")
      const now = new Date()
      this.selectedBudgets.length > 1 ?
        this.downloadXML(xmlWithEncoding, `XML_RECURSO_GLOSA_${nomeConvenio}_${this.formatDateNormal(now)}.xml`)
        : this.downloadXML(xmlWithEncoding, `XML_RECURSO_GLOSA_${nomePaciente}_${nomeConvenio}_${this.formatDateNormal(empresa.data_inicio)}_ate_${this.formatDateNormal(empresa.data_fim)}.xml`)
    },
    downloadXML(xml, filename) {
      try {
        // Cria um Blob com os dados XML
        const blob = new Blob([xml], { type: 'application/xml' });

        // Cria um URL para o Blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento de link temporário
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;

        // Adiciona o link ao documento e dispara o download
        document.body.appendChild(a);
        a.click();

        // Limpa e remove o link após o download
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Mensagem de sucesso
        this.$toast.success('O download do XML de Rescurso de Glosa foi realizado com sucesso!');
      } catch (error) {
        console.error('Erro ao salvar o arquivo:', error);
        this.$toast.error("Erro ao salvar o arquivo: " + error.message);
      }
    },
    formatDateNormal(value) {
      if (!value) return "";
      return moment(value).format("DD_MM_YYYY");
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.empresa = data[0];
          // console.log('Empresa --> ', this.empresa)
        } catch (error) {
          console.error('Erro ao buscar os dados da empresa:', error);
        }
      }
    },
    async getProcedimento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedBudgets[0]}`);
          this.procedimentos = data.filter(item => item.recurso_glosa === true && item.ativo === true);
          // console.log('Procedimento --> ', this.procedimentos)
        } catch (error) {
          console.error('Erro ao buscar os dados dos procedimentos:', error);
        }
      }
    },
  },
};
</script>